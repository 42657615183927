import React from 'react'
import { Container } from 'react-bootstrap'
import { useMenus } from '../../../hooks/use-menus'
import {
	subMenu,
	subMenuNav,
	subMenuList,
	subMenuListItem,
	subMenuWrapper
} from './Submenu.module.scss'
import Link from '../../Link'

/**
 * SubMenu to be used as Flexible component or by itself.  Use props.menu OR props.location, not BOTH.
 *
 * @param props
 * @param props.menu = pull a Wordpress menu
 * @param props.location = pull a menu assigned to a location
 * @param props.forceActive = specify a link to appear active even when window.location is different
 * @returns {JSX.Element}
 * @constructor
 */
const SubMenu = ({ ...props }) => {
	const menus = useMenus(props.location || props.menu)
	const menu = menus.length ? menus[0].menu : null
	
	return (
		<>
			{menu && (
				<div className={`block-sub-menu ${subMenu}`}>
					<Container fluid="lg" className={subMenuWrapper}>
						<nav
							className={`border-0 bg-light nav nav-tabs ${subMenuNav}`}
							role="navigation"
						>
							<ul className={`list-inline ${subMenuList}`}>
								{menu.menuItems.nodes.map((item, index) => {
									return (
										<li className={`list-inline-item ${subMenuListItem}`} key={`submenu-${index}`}>
											<Link
												to={item.url}
												className={`nav-item nav-link ${
													props.forceActive === item.url ? 'active' : ''
												}`}
												activeClassName={`active`}
											>
												{item.label}
											</Link>
										</li>
									)
								})}
							</ul>
						</nav>
					</Container>
				</div>
			)}
		</>
	)
}

export default SubMenu
