import React from 'react'
import {Link, graphql} from 'gatsby'
import Layout from '../../components/Layout'
import {Row, Container, Col} from "react-bootstrap"
import HeroHeadline from "../../components/FlexibleContent/HeroHeadline";
import StyledCard from "../../components/Common/StyledCard";
import Pagination from "../../components/Pagination";
import {listResources, resourcesPagination} from "./ListResources.module.scss"
import SubMenu from "../../components/FlexibleContent/SubMenu";
import ResourcesSearchForm from "../../components/Resources/SearchForm";
import Seo from "gatsby-plugin-wpgraphql-seo";
import {
	resourcesWrapper,
	headingWrapper
} from './ResourcesHeader.module.scss'
import MediaContact from "../../components/FlexibleContent/MediaContact";

const PageIndex = ({
										location,
										 data,
										 pageContext: {
											 nextPagePath,
											 previousPagePath,
											 currPage,
											 archivePath,
											 archiveTitle,
											 numPages,
											 offset,
											 postsPerPage,
											 totalResources,
										 },
									 }) => {
	let posts = data.allWpPost.nodes
	let featuredPosts = [];
	const metaDesc = `Procopio resources: ${archiveTitle}`

	if (!posts.length) {
		return (
			<Layout>
				<Seo post={{ seo: {
						title: `${archiveTitle}`,
						metaDesc: metaDesc,
						opengraphDescription: metaDesc,
					}
				}} />
				
				{/* display only on tablet+ */}
				<div className={`submenu-desktop d-none d-md-block`}>
					<SubMenu location={`resources`} forceActive="/videos/" />
				</div>

				<div className={headingWrapper}>
					<HeroHeadline
						headline={archiveTitle}
						includeContainer={true}
						headingSize="h1"
						includePadding={true}
						removeTopPadding={true}
					>
						<ResourcesSearchForm type="videos" />
					</HeroHeadline>
				</div>
				
				{/* displays only on mobile */}
				<div className={`submenu-mobile d-md-none ${resourcesWrapper}`}>
					<SubMenu location={`resources`} forceActive="/videos/" />
				</div>
			
				<Container fluid="md">
					<p>
						No videos found.
					</p>
				</Container>
			</Layout>
		)
	}

	if (currPage === 1) {
		featuredPosts = posts.slice(0, 4);
		posts = posts.slice(4, posts.length);
	}

	return (
		<Layout>
			<Seo post={{ seo: {
					title: `${archiveTitle} | Page ${currPage}`,
					metaDesc: metaDesc,
					opengraphDescription: metaDesc,
				}
			}} />
			
			{/* display only on tablet+ */}
      <div className={`submenu-desktop d-none d-md-block`}>
				<SubMenu location={`resources`} forceActive="/videos/" />
      </div>

			<div className={headingWrapper}>
				<HeroHeadline
					headline={archiveTitle}
					includeContainer={true}
					headingSize="h1"
					includePadding={true}
					removeTopPadding={true}
				>
					<ResourcesSearchForm type="videos" />
				</HeroHeadline>
			</div>
			
			{/* displays only on mobile */}
      <div className={`submenu-mobile d-md-none ${resourcesWrapper}`}>
				<SubMenu location={`resources`} forceActive="/videos/" />
      </div>

			<section className={listResources}>
				<Container fluid="md">
					{featuredPosts && (
						<Row>
							{featuredPosts.map((post, index) => {
								return (
									<Col sm={6} key={index}>
										<StyledCard
											backLink={location.pathname}
											variation="CardF"
											service={post?.resourceInformation?.service}
											label={null}
											href={post.uri}
											title={post.title}
											headingSize="title--sm"
											image={post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
											eventDetails={null}
											imageSize="medium-square"
											time={post.videoInformation?.duration}
											play={true}
										/>
									</Col>
								)
							})}
						</Row>
					)}

					{currPage === 1 && (
						<MediaContact viewType="list" />
					)}


					{posts && (
						<Row>
							{posts.map((post, index) => {
								return (
									<Col sm={6} md={4} key={index}>
										<StyledCard
											backLink={location.pathname}
											variation="CardF"
											service={post?.resourceInformation?.service}
											label={null}
											href={post.uri}
											title={post.title}
											headingSize="title--sm"
											image={post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData}
											eventDetails={null}
											imageSize="medium-square"
											time={post.videoInformation?.duration}
											play={true}
										/>
									</Col>
								)
							})}
						</Row>
					)}

					<Pagination
						itemName={archiveTitle}
						addClass={resourcesPagination}
						currPage={currPage}
						offset={offset}
						itemsPerPage={postsPerPage}
						totalItems={totalResources}
						numPages={numPages}
						previousPagePath={previousPagePath}
						nextPagePath={nextPagePath}
						archivePath={archivePath} />
				</Container>
			</section>


		</Layout>
	)
}

export default PageIndex

export const pageQuery = graphql`
	query WordPressVideoArchive($offset: Int!, $postsPerPage: Int!) {
		allWpPost(
			sort: { fields: [date], order: DESC }
			limit: $postsPerPage
			skip: $offset
			filter: {resourceTypes: {nodes: {elemMatch: {slug: {eq: "videos"}}}}}
		) {
			nodes {
				uri
				date(formatString: "MMMM DD, YYYY")
				title
				resourceTypes{
					nodes {
						slug
					}
				}
				categories {
					nodes {
						name
						uri
					}
				}
				videoInformation {
					duration
				}
				resourceInformation {
					service {
						... on WpService {
							title
							uri
							serviceTypes {
								nodes {
									slug
									name
								}
							}
						}
					}
				}
				featuredImage {
					node {
						altText
						localFile {
							childImageSharp {
								gatsbyImageData(
									aspectRatio: 1.77,
									layout: CONSTRAINED,
									width: 550
								)
							}
						}
					}
				}
			}
		}
	}
`
