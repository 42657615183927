import React from 'react'
import { navigate } from 'gatsby'
import SearchInput from "../../SearchInput";
import {Form} from "react-bootstrap";

const ResourcesSearchForm = ({type, ...props}) => {
  
  const handleSubmit = e => {
    e.preventDefault();
    const searchParams = new URLSearchParams(new FormData(e.target)).toString();
    navigate(`/resources/search/?${searchParams}`)
  }
  
  return (
    <Form onSubmit={handleSubmit}>
			{type && (
				<>
					<input type="hidden" name="taxonomy" value="pro_resource_type" />
					<input type="hidden" name="category[]" value={type} />
				</>
			)}
			<SearchInput iconSize="sm" placeholderText="Search resources" />
		</Form>
	)
}

export default ResourcesSearchForm
