import React from 'react'
import { Link } from 'gatsby'
import { pagination, paginationDescription } from './Pagination.module.scss'
import StyledIcon from '../Common/StyledIcon'

const Pagination = ({
	showText = true,
	itemName,
	addClass,
	currPage,
	offset,
	itemsPerPage,
	totalItems,
	numPages,
	previousPagePath,
	nextPagePath,
	archivePath,
	passState = null,
	onClick
}) => {
	const wrapperClass = [pagination]
	const paginationArray = createPagination(currPage, numPages)
	let ariaDescribedBy

	if (addClass) {
		wrapperClass.push(addClass)
	}

  itemName = itemName.replace('pro_', '');

	return (
		<div className={wrapperClass.join(' ')}>
			{showText && itemName && (
				<p
					className={'m-0 label-small text-uppercase ' + paginationDescription}
					id="paginationDesc"
				>
          {itemName} {offset + 1}-
					{offset + itemsPerPage > totalItems
						? totalItems
						: offset + itemsPerPage}{' '}
					of {totalItems}
				</p>
			)}

			{/* Mimic Bootstrap Pagination, but get the benefits of Gatsby Link */}
			{numPages > 1 && (
				<nav role="navigation" aria-label="Pagination for Articles">
					<ul className="pagination">
						{numPages > 3 && currPage > 1 && (
							<li className="page-item text-uppercase">
								<Link className="page-link" to={`${archivePath}`} state={passState} onClick={onClick}>
									First <span className="visually-hidden">Page</span>
								</Link>
							</li>
						)}

						{previousPagePath && (
							<li className="page-item">
								<Link className="page-link" to={previousPagePath} state={passState} onClick={onClick}>
									<span aria-hidden="true">
										<StyledIcon name="left" />
									</span>
									<span className="visually-hidden">Previous Page</span>
								</Link>
							</li>
						)}

						{paginationArray.map(i => {
							return i === '...' ? (
								<li
									className="page-item page-item-ellipsis"
									key={`pagination-number${i}`}
								>
									<span aria-hidden="true">{i}</span>
									<span className="visually-hidden">More</span>
								</li>
							) : (
								((ariaDescribedBy =
									i === currPage
										? {
												'aria-describedby': 'paginationDesc',
												'aria-current': 'page',
										  }
										: {}),
								(
									<li
										className={'page-item ' + (i === currPage ? 'active' : '')}
										key={`pagination-number${i}`}
									>
										<Link
											to={`${archivePath}${i === 1 ? '' : i}`}
											className="page-link"
											aria-label={`Page ${i}`}
											state={passState}
											onClick={onClick}
											{...ariaDescribedBy}
										>
											{i}
										</Link>
									</li>
								))
							)
						})}

						{nextPagePath && (
							<li className="page-item">
								<Link className="page-link" to={nextPagePath} state={passState} onClick={onClick}>
									<span aria-hidden="true">
										<StyledIcon name="right" />
									</span>
									<span className="visually-hidden">Next Page</span>
								</Link>
							</li>
						)}

						{numPages > 3 && currPage !== numPages && (
							<li className="page-item text-uppercase">
								<Link className="page-link" to={`${archivePath}${numPages}`} state={passState} onClick={onClick}>
									Last <span className="visually-hidden">Page</span>
								</Link>
							</li>
						)}
					</ul>
				</nav>
			)}
		</div>
	)
}

// found a pretty nice snippet here: https://gist.github.com/kottenator/9d936eb3e4e3c3e02598
const createPagination = (current, last) => {
	let delta = 2,
		left = current - delta,
		right = current + delta + 1,
		range = [],
		rangeWithDots = [],
		l

	// don't create a range with `...` if the number of pages isn't big enough
	if (last < 7) {
		return Array.from({ length: last }, (_, i) => i + 1)
	}

	for (let i = 1; i <= last; i++) {
		if (i == 1 || i == last || (i >= left && i < right)) {
			range.push(i)
		}
	}

	for (let i of range) {
		if (l) {
			if (i - l === 2) {
				rangeWithDots.push(l + 1)
			} else if (i - l !== 1) {
				rangeWithDots.push('...')
			}
		}
		rangeWithDots.push(i)
		l = i
	}

	return rangeWithDots
}

export default Pagination
