import { useStaticQuery, graphql } from 'gatsby'

export const useNewsletterLink = () => {
  const { wp } = useStaticQuery(
    graphql`
        query NewsletterLink {
            wp {
                procopioThemeSettings {
                    procopioThemeOptions {
                        newsletterLink
                    }
                }
            }
        }
    `
  )
  return wp?.procopioThemeSettings?.procopioThemeOptions?.newsletterLink
}
