import React from 'react'
import clsx from "clsx";
import Button from '../../Button'
import { Container, Row, Col } from 'react-bootstrap'
import {useNewsletterLink} from '../../../hooks/use-newsetter-link'
import {usePressContactCta} from "../../../hooks/use-press-contact-cta";
import {
	mediaBlock,
	darkBg,
	mediaBlockListView,
	mediaBlockDetailView,
	mediaNewsletter,
	mediaHeading,
	mediaContacts,
	mediaContactsElement,
	noBottomBorder
} from './MediaContact.module.scss'

const MediaContact = ({ contactPerson, newsletterCta, dark = false, viewType = null, removeBorderBottom = false, ...props }) => {
	const defaultData = usePressContactCta();
	const contacts = contactPerson ?? defaultData?.contactPerson
	const cta = newsletterCta ?? defaultData?.newsletterCta
	return (
		<>
			<div className={clsx(mediaBlock, (viewType === 'list' ? mediaBlockListView : viewType === 'detail' ? mediaBlockDetailView : ''), removeBorderBottom ? noBottomBorder : '')}>
			<Container>
				<Row className='justify-content-md-center'>
					{/* newsletter signup */}
					<Col md={4} className={mediaNewsletter}>
						{cta.newsletterHeading && (
							<h2
								className={mediaHeading}
								dangerouslySetInnerHTML={{
									__html: cta.newsletterHeading,
								}}
							/>
						)}

						<Button
							buttonText={cta?.ctaText}
							to={cta?.ctaLink?.url ?? useNewsletterLink()}
							theme={dark ? 'orange' : 'gray'}
							size={'small'}
						/>
					</Col>

					{/* media and event contacts */}
					<Col md={{ span: 5, offset: 1 }} className={mediaContacts}>
						{contacts?.map((person, index) => (
							// start contact
							<div className={clsx(mediaContactsElement, dark ? darkBg : '')} key={index}>
								{person.contactType && <h3>{person.contactType}</h3>}

								{/* contact links nested within p to establish context for screen reader users */}
								<p>
									{person.contactNameTitle}
									<br />
									{person.contactEmail && (
										<span>
											<a href={`mailto:${person.contactEmail}`}>Email</a>
										</span>
									)}
									{person.contactPhone && (
										<span>
											P:&nbsp;
											<a
												href={`tel:${person.contactPhone}`}
												aria-label={person.accessibleContactPhone}
											>
												{person.contactPhone}
											</a>
										</span>
									)}
									{person.contactFax && <span> F: {person.contactFax}</span>}
								</p>
							</div>
							// end contact
						))}
					</Col>
				</Row>
			</Container>
			</div>
		</>
	)
}

export default MediaContact
