import { useStaticQuery, graphql } from 'gatsby'

export const usePressContactCta = () => {
	const { wp } = useStaticQuery(
		graphql`
			query PressContactCta {
				wp {
					procopioThemeSettings {
						procopioPressContactCtaOptions {
							newsletterCta {
								ctaText
								ctaLink {
									url
								}
								newsletterHeading
							}
							contactPerson {
								accessibleContactPhone
								contactEmail
								contactFax
								contactNameTitle
								contactPhone
								contactType
							}
						}
					}
				}
			}
		`
	)
	return {
		contactPerson: wp?.procopioThemeSettings?.procopioPressContactCtaOptions?.contactPerson,
		newsletterCta: wp?.procopioThemeSettings?.procopioPressContactCtaOptions?.newsletterCta
	}

}
