import React from 'react'
import { Row, Container, Col } from 'react-bootstrap'
import SubMenu from './../SubMenu'
import { heroHeadline, includePadding, heroContent, noTopPadding, hasMenu, menuWrapper } from './HeroHeadline.module.scss'

// Note: Separate mobile and desktop specific markup is needed to preserve logical tabbing order for keyboard users.
// Cannot use CSS order as it will not preserve tabbing order

const HeroHeadline = ({ children, ...props }) => {
	const sectionClass = [heroHeadline]
	const id = props.id || null
	const HeadingTag = `${props.headingSize ? props.headingSize : 'h1'}`
	const padding = props.includePadding ? sectionClass.push(includePadding) : null
	const menu = props.menu ? sectionClass.push(hasMenu) : null
	const submenuSibling = props.prevBlock?.endsWith('Submenu') || props.removeTopPadding ? sectionClass.push(noTopPadding) : null
	const heading =
		<HeadingTag
			className="title--xl"
			dangerouslySetInnerHTML={{ __html: props.headline }}
			id={id}
		/>

	return (
		<div className={sectionClass.join(' ')}>
			{props.includeContainer ? (
				<Container fluid="lg">
					{children ? (
						<>
							{/* display only on tablet+ */}
							<div className={`submenu-desktop d-none d-md-block ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
								
							<Row>
								<Col md={4}>{heading}</Col>
								<Col className={heroContent}>{children}</Col>
							</Row>
							
							{/* displays only on mobile */}
							<div className={`submenu-mobile d-md-none ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
						</>
					) : (
						<>
						 	{/* display only on tablet+ */}
						 	<div className={`submenu-desktop d-none d-md-block ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
							
							{heading}
							
							{/* displays only on mobile */}
							<div className={`submenu-mobile d-md-none ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
						</>
					)}
				</Container>
			) : (
				<>
					{children ? (
						<>
							{/* display only on tablet+ */}
							<div className={`submenu-desktop d-none d-md-block ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
							
							<Row>
								<Col md={4}>{heading}</Col>
								<Col>{children}</Col>
							</Row>
						
							{/* displays only on mobile */}
							<div className={`submenu-mobile d-md-none ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
						</>
					) : (
						<>
							{/* display only on tablet+ */}
							<div className={`submenu-desktop d-none d-md-block ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
							
							{heading}
							
							{/* displays only on mobile */}
							<div className={`submenu-mobile d-md-none ${menuWrapper}`}>
								<SubMenu {...props} />
							</div>
						</>
					)}
				</>
			)}
		</div>
	)
}

export default HeroHeadline
